<template>
  <div class="guide">
    <div class="guide_left">
      <dl>
        <dt>
          <p>高佣不怕比</p>
          <p>省赚指南</p>
          <p></p>
        </dt>
        <dd>
          <span style="font-weight: bold">介绍内容</span><br />
          好返伙伴是依托互联网技术发展产生的电商导购平台app，汇聚全网电商购物、吃喝玩乐等平台的商品优惠，让消费者网购更方便，下单更优惠，并同时享受各大平台购物权益保障。
          <br /><span style="font-weight: bold">小字信息</span><br />
          ①　软件名称：好返伙伴 ②　开发商：郑州好返伙伴科技有限公司
          ③　软件平台：ios/android ④　软件授权：免费 ⑤　软件语言：中文
          ⑥　软件大小：54.2M <br /><span style="font-weight: bold"
            >软件介绍</span
          ><br />
          好返伙伴是一个全网全品类综合优惠导购分享型社交电商平台。以社交电商的新经济模式，助力商家更多曝光并提高收益和效率，同时给平台带来流量和业绩增长，消费者可通过社群推广获取收益，也可以自买优惠。
          好返伙伴app全方位覆盖生活场景，把优惠券、折扣、代金券、下单返利等优惠融入到人们日常生活所需的方方面面。
          从衣食住行，到吃喝玩乐。无论是淘宝的潮流单品、京东的品质好物，还是拼多多的性价比之选、唯品会的品牌特惠，还是抖音、苏宁、美团、饿了么、滴滴出行、飞猪等各大主流平台好返伙伴都有合作，让用户轻松享受各大电商平台的优惠与便利。
        </dd>
      </dl>
    </div>
    <div class="guide_right">
      <van-swipe
        :autoplay="false"
        :show-indicators="false"
        ref="guide_banner"
        @change="onChangeGuide"
      >
        <van-swipe-item v-for="(item, index) in videoList" :key="index">
          <video
            :style="{
              width: '789px',
              height: '444px',
              background: '#000000',
            }"
            :controls="true"
            muted
            preload
            :ref="'video_' + index"
            :key="index"
            :allowfullscreen="true"
            :poster="item.coverUrl"
          >
            <source :src="item.url" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
          <p>{{ item.desc }}</p>
        </van-swipe-item>
      </van-swipe>
      <div class="arrow_wrap flex-row-between">
        <van-image
          :src="arrowLeft"
          width="74px"
          height="74px"
          :preview="false"
          class="prevImg"
          @click="bannerPrev()"
        />
        <van-image
          :src="arrowRight"
          width="74px"
          height="74px"
          :preview="false"
          class="nextImg"
          @click="bannerNext()"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arrowLeft:
        "https://images.haofanhuoban.com/decoration/a3b01e610602fff433a72a90f858e220.png",
      arrowRight:
        "https://images.haofanhuoban.com/decoration/09dc51c6099867afce2bdba6bc909bfb.png",
      videoList: [
        {
          url: "https://images.haofanhuoban.com/decoration/c9d35022d5fde444c8f9cd3565c6ab75.mp4",
          coverUrl:
            "https://images.haofanhuoban.com/decoration/5cfafb016f558a67820f0ad81734df06.png",
          desc: "一分钟快速了解【好返伙伴】",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/c6997a154807dc5aa6d74cf980f3cad8.mp4",
          coverUrl:
            "https://images.haofanhuoban.com/decoration/74c992fdd2273cca7500ed4da6856b48.png",
          desc: "如何用好返APP省钱购物？",
        },
      ],
    };
  },
  mounted() {
    // 添加事件监听器
    window.addEventListener("resize", this.$refs.guide_banner.resize());
  },
  methods: {
    onChangeGuide(e) {
      console.log(e);
    },
    bannerNext() {
      this.$refs.guide_banner.next();
    },
    bannerPrev() {
      this.$refs.guide_banner.prev();
    },
  },
};
</script>
<style lang="scss" scoped>
.guide {
  width: 73vw;
  height: 1300px;
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  .guide_left {
    // width: 36vw;
    flex: 1;
    // max-width: 710px;
    padding-bottom: 400px;
    dl {
      padding-right: 4vw;
      dt {
        color: #232323;
        p {
          &:nth-child(1) {
            font-weight: 500;
            font-size: 40px;
            line-height: 1;
          }
          &:nth-child(2) {
            font-size: 26px;
            line-height: 1;
            margin-top: 25px;
          }
          &:nth-child(3) {
            width: 75px;
            height: 7px;
            background: linear-gradient(180deg, #d914fc, #7c0bf5);
            border-radius: 4px;
            margin-top: 28px;
          }
        }
      }
      dd {
        height: 680px;
        font-weight: 400;
        font-size: 20px;
        color: #333333;
        line-height: 39px;
        margin-top: 20px;
      }
    }
  }
  .guide_right {
    position: relative;
    flex-shrink: 0;
    flex: 1;
    max-width: 789px;
    margin-top: 200px;
    ::-webkit-scrollbar {
      height: 0;
    }
    .van-swipe {
      max-width: 789px;
    }
    p {
      color: #666;
      font-size: 22px;
      max-width: 789px;
      text-align: center;
      margin-top: 40px;
    }
    .arrow_wrap {
      .nextImg {
        position: absolute;
        right: 31px;
        top: 50%;
        transform: translate(0, calc(-50% - 37px));
      }
      .prevImg {
        position: absolute;
        left: 31px;
        top: 50%;
        transform: translate(0, calc(-50% - 37px));
      }
    }
  }
}
/* 当屏幕宽度大于或等于1600像素时，增加字体大小 */
@media (max-width: 1536px) {
  video,
  :deep(.van-swipe) {
    width: 631.2px !important;
  }
  video {
    height: 355.2px !important;
  }
}
/* 当屏幕宽度大于或等于1200像素时，增加字体大小 */
@media (max-width: 1200px) {
  video,
  :deep(.van-swipe) {
    width: 493.125px !important;
  }
  video {
    height: 277.5px !important;
  }
}
</style>
